import React from 'react';
import styled from 'styled-components';
import ContentSection from './styles/ContentSection';
import CenteredDiv from './styles/CenteredDiv';

const StyledMap = styled.iframe`
  width: 90vw;
  height: 80vh;
  border: none;
`;

const WhereToBuy = () => {
  return (
    <ContentSection id="whereToBuy" color="navy">
      <CenteredDiv>
        <StyledMap class="snazzyMap" src="https://snazzymaps.com/embed/4563" />
      </CenteredDiv>
    </ContentSection>
  );
};

export default WhereToBuy;
