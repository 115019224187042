import styled from 'styled-components';

const CenteredDiv = styled.div`
  height: 100%;
  width: 100%;
  min-height: ${props => props.theme.minHeight};
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
`;

export default CenteredDiv;
